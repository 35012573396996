import React from 'react';
import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import Layout from '../components/v2/templates/Layout';
import { graphql } from 'gatsby';
import useStoryblok from '../lib/storyblok';
import { StoryblokPage } from '../types';

const Success: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <div className="flex h-screen items-center justify-center" id="product-demo-form-section">
        <div className="flex flex-col items-center justify-center">
          <CheckCircleIcon className="fill-green-700 w-20 h-20" />
          <p className="text-xl text-gray-600 font-medium italic pt-4">
            Thank you for submitting a demo request.
          </p>
          <p className="text-xl text-gray-600 font-medium italic pb-4">
            Someone from Homeppl will email you shortly.
          </p>
          <a
            href="/"
            className=" text-xl text-gray-600 font-medium italic hover:text-gray-700 focus:text-gray-900 underline"
          >
            <div className="flex items-center space-x-2">
              <ArrowLeftIcon className="w-4 h-4" />
              <p>Back To Homepage</p>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Success;

export const query = graphql`
  query SuccessQuery {
    storyblokEntry(full_slug: { eq: "contact-us" }) {
      content
      name
    }
  }
`;
